body {
  margin: 1;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.content{
  height: 100%;
  width: 100%;
}

.app{
  display: flex;
  position: relative;
}
/* scrolbar */
::-webkit-scrollbar{
  width: 10px;
}

/* track */

::-webkit-scrollbar-track{
  background:#e0e0e0;
}

/* hadnle */
::-webkit-scrollbar-thumb{
  background:#888;
}

/* hadnle hover*/
::-webkit-scrollbar-track :hover{
  background:#3e85e3;

}



